<template>
    <div class="info-pages-main">
        <div class="info-pages-main__wrapper">
            <article class="info-pages-main__body">
                <div id="post" ref="post" class="privacy-description-block" v-html="content" />
            </article>
            <!-- <aside v-if="isIndexAvailable" class="info-pages-main__sidebar">
                <ul class="description-block">
                    <h2 class="description-block__index">
                        {{ $lang.components.layoutInfoPages.index }}
                    </h2>
                    <li
                        v-for="(item, key) in postIndex"
                        :key="key"
                        class="description-block__link"
                        @click="scrollTo(`#${item.id}`)"
                    >
                        {{ item.text }}
                    </li>
                </ul>
            </aside> -->
        </div>
    </div>
</template>

<script lang="ts">
type postIndex = {
    id: string
    text: string
}

export default defineComponent({
    props: {
        content: {
            type: String,
            required: true,
        },
    },
    data() {
        const scrollTo = useScrollToComponent()
        return { scrollTo, postIndex: [] as postIndex[], isIndexAvailable: false }
    },
    mounted() {
        this.getTextIndex()
    },
    methods: {
        getTextIndex(): void {
            if (this.$refs.post) {
                const h2 = (this.$refs.post as HTMLElement).querySelectorAll('h2')
                // check if h2 element has text, then get innerText and id and creates a object with it, and sets an id to each h2
                if (h2.length > 0) {
                    h2.forEach((el: HTMLElement, key: number) => {
                        if (el.innerText.length > 0) {
                            const id = 'post-' + key
                            el.id = id
                            this.postIndex.push({
                                id,
                                text: el.innerText,
                            })
                        }
                    })
                }
                if (this.postIndex.length > 0) this.isIndexAvailable = true
            }
        },
    },
})
</script>

<style lang="postcss" scoped>
.info-pages {
    &-main {
        @apply container mx-auto my-4 mb-2 mt-2 px-2 sm:p-3 lg:max-w-5xl lg:px-0;

        /* &__wrapper {
            @apply max-w-5xl;
        } */
        &__body {
            @apply lg:order-2 lg:col-span-8 lg:col-start-3 lg:pl-3;
            .main-content {
                @apply rounded-2xl;
                h1 {
                    @apply text-xl font-semibold text-gray-800;
                }
            }
        }
        &__sidebar {
            @apply relative space-y-5 pb-4 lg:static lg:order-1 lg:col-span-2 lg:col-start-1 lg:py-5 lg:pt-0;
        }
    }
}

.description-block {
    @apply sticky top-20 rounded-2xl border border-opacity-5 bg-white p-3;

    &__index {
        @apply mb-4 text-lg text-site-primary md:text-[28px];
    }

    &__link {
        @apply mb-2 block cursor-pointer hover:underline;
    }
}

.privacy-description-block {
    @apply prose min-w-full rounded-2xl border border-opacity-5 bg-white px-8 py-6 prose-headings:font-normal prose-h2:text-xl lg:prose-h2:text-[28px];
}
</style>
